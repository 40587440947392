<template>
   <b-card>
	  <b-alert variant="danger">
		 <h4 class="alert-heading">{{ $t('contacts.error') }}</h4>
		 <div class="alert-body">
			{{ $t('contacts.error-msg') }}
			<b-link class="alert-link"> {{ $t('contacts.contacts-list') }}</b-link>
			{{ $t('contacts.contacts-more') }}
		 </div>
	  </b-alert>
	  <validation-observer ref="observer"
						   v-slot="{handleSubmit , invalid}">
		 <b-form>
			<b-tabs pills>
			   <b-tab active>
				  <template #title>
					 <feather-icon class="mr-0 mr-sm-50"
								   icon="UserIcon"
								   size="16" />
					 <span class="d-none d-sm-inline">{{ $t('contacts.general') }}</span>
				  </template>
				  <!--start:acounts-->
				  <b-media class="mb-2">
					 <template #aside>
						<b-avatar
							v-if="form.photo"
							ref="previewEl"
							:src="form.photo"
							:text="fullName"
							rounded
							size="90px"
							variant="light"
						/>
						<b-avatar v-else
								  :text="fullName"
								  :variant="getColor"
								  rounded
								  size="90px"
						/>
					 </template>
					 <h4 class="mb-1">
						{{ form.surname }} {{ form.name }}
					 </h4>
					 <div class="d-flex flex-wrap">
						<label class="d-none mr-1 d-sm-inline cursor-pointer btn btn-outline-secondary"
							   for="file">

						   <input
							   id="file"
							   ref="refInputEl"
							   accept=".jpg,.jpeg,.png"
							   class="d-none"
							   type="file"
							   @change="onPreview($event,form)"
						   >
						   {{ $t('contacts.change') }}
						   <feather-icon
							   class="d-inline d-sm-none"
							   icon="EditIcon"
						   />

						</label>

						<label class="d-none d-sm-inline cursor-pointer btn btn-outline-secondary"
							   @click="removeImage(form)">
						   {{ $t('contacts.remove') }}
						   <feather-icon
							   class="d-inline d-sm-none"
							   icon="TrashIcon"
						   />
						</label>
					 </div>
				  </b-media>
				  <b-row>

					 <b-col cols="12"
							md="6">

						<!--lastname-->
						<validation-provider v-slot="validationContext"
											 :rules="{ required: true}">
						   <b-form-group
							   :label="$t('contacts.surname')"
							   class="mt-1"
							   label-for="surname"
						   >
							  <b-input-group :class="getValidationClass(validationContext)"
											 class="input-group-merge">
								 <b-input-group-prepend is-text>
									<feather-icon icon="UserIcon" />
								 </b-input-group-prepend>
								 <b-form-input id="surname"
											   v-model="form.surname"
											   :state="getValidationState(validationContext)"
											   placeholder="Doe" />
							  </b-input-group>
							  <b-form-invalid-feedback id="form-surname-lice-feedback">
								 {{ validationContext.errors[0] }}
							  </b-form-invalid-feedback>
						   </b-form-group>
						</validation-provider>

						<!--firstname-->
						<validation-provider v-slot="validationContext"
											 :rules="{ required: true}">
						   <b-form-group
							   :label="$t('contacts.name')"
							   class="mt-1"
							   label-for="name">
							  <b-input-group :class="getValidationClass(validationContext)"
											 class="input-group-merge">
								 <b-input-group-prepend is-text>
									<feather-icon icon="UserIcon" />
								 </b-input-group-prepend>
								 <b-form-input id="name"
											   v-model="form.name"
											   :state="getValidationState(validationContext)"
											   placeholder="John" />
							  </b-input-group>
							  <b-form-invalid-feedback id="form-name-lice-feedback">
								 {{ validationContext.errors[0] }}
							  </b-form-invalid-feedback>
						   </b-form-group>

						</validation-provider>


						<!--email-->
						<validation-provider v-slot="validationContext"
											 :rules="{required:false, email: true}">
						   <b-form-group
							   class="mt-1"
							   label="E-mail"
							   label-for="email"
						   >
							  <b-input-group :class="getValidationClass(validationContext)"
											 class="input-group-merge">
								 <b-input-group-prepend is-text>
									<feather-icon icon="MailIcon" />
								 </b-input-group-prepend>
								 <b-form-input id="email"
											   ref="email"
											   v-model="form.email"
											   :state="getValidationState(validationContext)"
											   placeholder="mail@site.com"
											   type="email"
											   @blur="removeValidatorIcon('email')"
											   @keyup="removeValidatorIcon('email')" />
							  </b-input-group>
							  <b-form-invalid-feedback>
								 {{ validationContext.errors[0] }}
							  </b-form-invalid-feedback>
						   </b-form-group>
						</validation-provider>

						<!--gender-->
						<b-form-group :label="$t('contacts.gender')"
									  class="mt-1">
						   <b-form-radio-group
							   v-model="form.gender"
							   :options="GenderOptions"
							   class="mb-1"
							   name="gender"
						   />
						</b-form-group>

						<!--pref contact-->
						<b-form-group :label="$t('contacts.pref-communication')"
									  class="mt-1">
						   <b-form-checkbox-group
							   v-model="form.contactPreference"
							   :options="contactOptions"
							   name="contactpref"
						   />
						</b-form-group>

					 </b-col>

					 <b-col cols="12"
							md="6">

						<!--mobile-->
						<validation-provider v-slot="validationContext"
											 :rules="{ numeric: true , max:10}"
											 name="mobile">
						   <b-form-group
							   :label="$t('contacts.mobile')"
							   class="mt-1"
							   label-for="mobile"
						   >
							  <b-input-group :class="getValidationClass(validationContext)"
											 class="input-group-merge">
								 <b-input-group-prepend is-text>
									<feather-icon icon="SmartphoneIcon" />
								 </b-input-group-prepend>
								 <b-form-input id="mobile"
											   ref="mobile"
											   v-model="form.mobile"
											   :state="getValidationState(validationContext)"
											   placeholder="6988888888"
											   @blur="removeValidatorIcon('mobile')"
											   @keyup="removeValidatorIcon('mobile')" />
							  </b-input-group>
							  <b-form-invalid-feedback v-if="validationContext.errors[0]">
								 {{ $t(getError(validationContext.errors[0])) }}
							  </b-form-invalid-feedback>
						   </b-form-group>
						</validation-provider>

						<!--telephone-->
						<validation-provider v-slot="validationContext"
											 :rules="{ numeric: true,max:10}"
											 name="telephone">
						   <b-form-group
							   :label="$t('contacts.phone')"
							   class="mt-1"
							   label-for="phone"
						   >
							  <b-input-group :class="getValidationClass(validationContext)"
											 class="input-group-merge">
								 <b-input-group-prepend is-text>
									<feather-icon icon="PhoneIcon" />
								 </b-input-group-prepend>
								 <b-form-input
									 id="phone"
									 ref="telephone"
									 v-model="form.telephone"
									 :state="getValidationState(validationContext)"
									 placeholder="2101234567"
									 @blur="removeValidatorIcon('telephone')"
									 @keyup="removeValidatorIcon('telephone')" />
							  </b-input-group>
							  <b-form-invalid-feedback v-if="validationContext.errors[0]">
								 {{ $t(getError(validationContext.errors[0])) }}
							  </b-form-invalid-feedback>
						   </b-form-group>
						</validation-provider>

						<!--website-->
						<b-form-group
							:label="$t('contacts.website')"
							class="mt-1"
							label-for="website"
						>
						   <b-input-group class="input-group-merge">
							  <b-input-group-prepend is-text>
								 <feather-icon icon="GlobeIcon" />
							  </b-input-group-prepend>
							  <b-form-input id="website"
											v-model="form.website"
											placeholder="mysite.com" />
						   </b-input-group>
						</b-form-group>

						<!--dob-->
						<b-form-group
							:label="$t('contacts.birthdate')"
							class="m-0 p-0"
							label-for="birth-date"
						>
						   <date-picker
							   v-model="form.dob"
							   :show-time-header="true"
							   class="w-100"
							   format="DD-MM-YYYY"
							   type="date"
							   valueType="YYYY-MM-DD" />

						</b-form-group>

						<!--category-->
						<b-form-group
							:label="$t('contacts.category')"
							class="mt-1"
							label-for="contactCategory"
						>
						   <!--						   :value="planFilter"-->
						   <v-select
							   :options="categoriesTitle"
							   :value="getValueCategory"
							   item-text="title"
							   @input="(val) => selectedCategory(val)"
						   >
							  <template v-slot:no-options>{{ $t('errors.no-matching') }}</template>
						   </v-select>
						</b-form-group>

					 </b-col>

				  </b-row>

				  <!--start:acounts-->
			   </b-tab>
			   <b-tab>
				  <template #title>
					 <feather-icon class="mr-0 mr-sm-50"
								   icon="InfoIcon"
								   size="16" />
					 <span class="d-none d-sm-inline">{{ $t('contacts.company') }}</span>
				  </template>
				  <!--start:company-->
				  <b-row>
					 <!--company-->
					 <b-col cols="12"
							md="6">
						<div class="d-flex mb-1">
						   <feather-icon
							   icon="UserIcon"
							   size="19"
						   />
						   <h4 class="mb-0 ml-50">
							  {{ $t('contacts.company-info') }}
						   </h4>
						</div>

						<b-form-group
							:label="$t('contacts.company-name')"
							class="mt-1"
							label-for="company_name">
						   <b-form-input id="company_name"
										 v-model="form.companyName" />
						</b-form-group>

						<b-form-group
							:label="$t('contacts.company-type')"
							class="mt-1"
							label-for="company_type">
						   <b-form-input id="company_type"
										 v-model="form.activity" />
						</b-form-group>
						<validation-provider v-slot="validationContext"
											 :rules="{ numeric: true , max:10}">
						   <b-form-group
							   :class="getValidationClass(validationContext)"
							   :label="$t('contacts.company-vat')"
							   class="mt-1"
							   label-for="company_vat">
							  <b-form-input id="company_vat"
											ref="vat"
											v-model="form.vat"
											:state="getValidationState(validationContext)"
											@blur="removeValidatorIcon('vat')"
											@keyup="removeValidatorIcon('vat')" />
							  <b-form-invalid-feedback v-if="validationContext.errors[0]">
								 {{ $t(getError(validationContext.errors[0])) }}
							  </b-form-invalid-feedback>
						   </b-form-group>
						</validation-provider>

						<b-form-group
							:label="$t('contacts.company-doy')"
							class="mt-1"
							label-for="company_doy">
						   <b-form-input id="company_doy"
										 v-model="form.doy" />
						</b-form-group>

					 </b-col>

					 <!--company address-->
					 <b-col cols="12"
							md="6">

						<div class="d-flex mb-1">
						   <feather-icon
							   icon="MapPinIcon"
							   size="19"
						   />
						   <h4 class="mb-0 ml-50">
							  {{ $t('contacts.address') }}
						   </h4>
						</div>

						<b-form-group
							:label="$t('contacts.address')"
							class="mt-1"
							label-for="company_address">
						   <b-form-input id="company_address"
										 v-model="form.address" />
						</b-form-group>

						<b-row>
						   <b-col cols="12"
								  md="6">
							  <b-form-group
								  :label="$t('contacts.city')"
								  class="mt-1"
								  label-for="company_city">
								 <b-form-input id="company_city"
											   v-model="form.city" />
							  </b-form-group>
						   </b-col>
						   <b-col cols="12"
								  md="6">
							  <validation-provider v-slot="validationContext"
												   :rules="{ numeric: true , max:5}">
								 <b-form-group
									 :class="getValidationClass(validationContext)"
									 :label="$t('contacts.zip')"
									 class="mt-1"
									 label-for="company_zip">
									<b-form-input id="company_zip"
												  ref="postcode"
												  v-model="form.postcode"
												  :state="getValidationState(validationContext)"
												  @blur="removeValidatorIcon('postcode')"
												  @keyup="removeValidatorIcon('postcode')" />
									<b-form-invalid-feedback> {{
										  validationContext.errors[0]
															  }}
									</b-form-invalid-feedback>

								 </b-form-group>

							  </validation-provider>

						   </b-col>
						</b-row>

						<b-form-group
							:label="$t('contacts.county')"
							class="mt-1"
							label-for="company_county">
						   <b-form-input id="company_county"
										 v-model="form.state" />
						</b-form-group>

						<b-form-group
							:label="$t('contacts.country')"
							class="mt-1"
							label-for="company_country">
						   <b-form-input id="company_country"
										 v-model="form.country" />
						</b-form-group>

					 </b-col>
				  </b-row>
				  <!--			end:company-->
			   </b-tab>
			   <b-tab>
				  <template #title>
					 <feather-icon class="mr-0 mr-sm-50"
								   icon="Share2Icon"
								   size="16" />
					 <span class="d-none d-sm-inline">{{ $t('contacts.social') }}</span>
				  </template>
				  <b-row
					  v-for="(item, index) in form.social"
					  :id="item.id"
					  :key="item.id"
					  ref="row"
				  >

					 <!-- Social -->
					 <b-col cols="6"
							md="3">
						<b-form-group
							class="mt-1"
							label="Social"
							label-for="socials-list"
						>
						   <v-select
							   v-model="form.social[index].medium"
							   :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							   :options="socialsList"
							   append-to-body
							   input-id="socials-list"
							   label="title"
						   />
						</b-form-group>
					 </b-col>

					 <!-- Item Name -->
					 <b-col md="4">
						<b-form-group
							class="mt-1"
							label="URL ή Username"
							label-for="item-name"
						>
						   <b-form-input
							   id="item-name"
							   v-model="form.social[index].url"
							   type="text"
						   />
						</b-form-group>
					 </b-col>

					 <!-- Remove Button -->
					 <b-col
						 class="mb-50 mt-1"
						 col
						 md="3"
					 >

						<b-button
							v-ripple.400="'rgba(234, 84, 85, 0.15)'"
							class="mt-0  rounded-circle mt-md-2 btn-icon"
							variant="outline-danger"
							@click="removeItem(index)"
						>
						   <feather-icon
							   icon="TrashIcon"
						   />
						</b-button>
						<b-button
							v-if="index === form.social.length - 1"
							v-ripple.400="'rgba(113, 102, 240, 0.15)'"
							class="mt-0 ml-1 mt-md-2 btn-icon rounded-circle"
							variant="success"
							@click="repeatAgain"
						>
						   <feather-icon
							   icon="PlusIcon"
						   />
						</b-button>
					 </b-col>
					 <b-col cols="12">
						<hr>
					 </b-col>
				  </b-row>

			   </b-tab>
			   <b-tab>
				  <template #title>
					 <feather-icon class="mr-0 mr-sm-50"
								   icon="PenToolIcon"
								   size="16" />
					 <span class="d-none d-sm-inline">{{ $t('contacts.notes') }}</span>
				  </template>
				  <!--start:notes-->
				  <b-row>
					 <b-col cols="12">
						<quillEditor v-model="form.notes"
									 :options="editorOptions"
									 placeholder="asd" />
					 </b-col>
				  </b-row>
				  <!--end:notes-->
			   </b-tab>
			</b-tabs>
			<b-col class="mt-2 px-0">
			   <b-button
				   :block="$store.getters['app/currentBreakPoint'] === 'xs'"
				   :disabled="invalid"
				   class="mb-1 mb-sm-0 mr-0 mr-sm-1"
				   variant="primary"
				   @click="onUpdate"
			   >
				  {{ $t('generic.save') }}
			   </b-button>
			</b-col>
		 </b-form>
	  </validation-observer>
   </b-card>
</template>

<script>
import {
   BAlert,
   BAvatar,
   BButton,
   BCard,
   BCardText,
   BCol,
   BForm,
   BFormCheckbox,
   BFormCheckboxGroup,
   BFormGroup,
   BFormInput,
   BFormInvalidFeedback,
   BFormRadio,
   BFormRadioGroup,
   BInputGroup,
   BInputGroupPrepend,
   BLink,
   BMedia,
   BRow,
   BTab,
   BTabs
} from "bootstrap-vue";
import ToastMixin from "@/Mixins/ToastMixin";
import vSelect from "vue-select";
import BCardCode from "@core/components/b-card-code/BCardCode";
import {quillEditor} from "vue-quill-editor";
import {heightTransition} from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import ImageMixin from "@/Mixins/ImageMixin";
import FormMixin from "@/Mixins/FormMixin";
import {extend, ValidationObserver, ValidationProvider} from "vee-validate";
import {email, max, numeric, required} from "vee-validate/dist/rules";
import axiosIns from "@/libs/axios";
import _ from "lodash";
import {avatarText} from '@core/utils/filter';
import caldoSettings from "@/caldoSettings";
import {Greek} from "flatpickr/dist/l10n/gr.js"

extend('required', {
   ...required,
   message: 'Υποχρεωτικό πεδίο'

})
extend('numeric', {
   ...numeric,
   message: 'the-must-be-a-number'

})

extend("max", {
   ...max,
   message: 'max-ten-number'
});


extend('email', {
   ...email,
   message: 'Μόρφη Email'
})


export default {
   components: {
	  BCard,
	  BAlert,
	  BLink,
	  BTabs,
	  BTab,
	  BFormInvalidFeedback,
	  BMedia,
	  Greek,
	  BAvatar,
	  BButton,
	  BForm,
	  BRow,
	  BCol,
	  BFormGroup,
	  BFormInput,
	  BInputGroup,
	  BInputGroupPrepend,
	  BFormRadio,
	  BFormRadioGroup,
	  BFormCheckboxGroup,
	  BFormCheckbox,
	  vSelect,
	  BCardCode,
	  BCardText,
	  quillEditor,
	  ValidationObserver,
	  ValidationProvider

   },
   directives: {
	  Ripple,
   },
   mixins: [ToastMixin, heightTransition, ImageMixin, FormMixin],


   data() {
	  return {
		 editorOptions: {
			placeholder: this.$i18n.t('generic.insert-text-here'),
			theme: 'snow'
		 },
		 items: [],
		 nextSocialId: 2,
		 selectedSocial: [],
		 socialsList: ['facebook', 'twitter', 'instagram', 'linkedin', 'slack', 'skype', 'pinterest'],
		 gender: 'male',
		 GenderOptions: [
			{text: 'Άνδρας', value: 'm'},
			{text: 'Γυναίκα', value: 'f'},
			{text: 'Άλλο', value: 'o'},
		 ],
		 contactpref: [],
		 contactOptions: [
			{text: 'E-mail', value: 'email'},
			{text: 'Μήνυμα', value: 'message'},
			{text: 'Τηλέφωνο', value: 'telephone'},
		 ],
		 categories: [],
		 categoriesTitle: [],
		 user: {},
		 form: {
			name: null,
			surname: null,
			email: null,
			mobile: null,
			telephone: null,
			gender: null,
			contactPreference: [],
			website: null,
			dob: null,
			categoryId: null,
			companyName: null,
			activity: null,
			vat: null,
			doy: null,
			address: null,
			city: null,
			postcode: null,
			state: null,
			country: null,
			notes: null,
			social: [{}],
			photoType: null,
			photo: null
		 }
	  }

   },

   computed: {
	  getValueCategory() {
		 return _.find(this.categories, {id: this.form.categoryId})?.title;
	  },
	  fullName() {
		 return avatarText(this.form.surname, this.form.name)
	  },
	  getColor() {
		 return caldoSettings.getColor(this.user.color)
	  }
   },
   methods: {
	  addSocialIfIsEmpty() {
		 if (!this.form.social.length) {
			this.form.social.push({});
		 }
	  },

	  getValidationState({dirty, validated, valid = null}) {

		 this.getValidationClass(validated)
		 return validated ? valid : null;
	  },

	  getValidationClass(validated) {
		 if ((validated.valid !== undefined && validated.validated === true))
			return !validated.valid ? 'is-invalid' : ''
	  },

	  async onUpdate(e) {
		 const cloneForm = _.cloneDeep(this.form)
		 const id = this.$route.params.userId;

		 var re = new RegExp("^(http|https)://", "i");
		 var str = cloneForm.photo;
		 var match = re.test(str);
		 if (match) {
			delete cloneForm.photo
		 }

		 try {
			await this.$store.dispatch("updateContact", {payload: cloneForm, id})
			this.addSocialIfIsEmpty();

			this.notify(`Αποθηκεύτηκε`)
		 } catch (e) {
			this.$refs.observer.setErrors(this.$store.getters.getErrorsCompany)

		 }


	  },
	  repeatAgain(e) {
		 this.items.push({
			id: this.nextSocialId += this.nextSocialId,
		 })
		 this.form.social.push({});
	  },
	  removeItem(index) {
		 this.form.social.splice(index, 1)
	  },
	  getError(error) {
		 return `errors.${error.toLowerCase().replaceAll(' ', '-').replace('.', '')}`
	  },
	  selectedCategory(category) {
		 this.form.categoryId = _.find(this.categories, {title: category}).id ?? [];
	  },

	  async initCurrentUser() {
		 this.form.name = this.user.name;
		 this.form.surname = this.user.surname;
		 this.form.email = this.user.email;
		 this.form.mobile = this.user.mobile;
		 this.form.telephone = this.user.telephone;
		 this.form.gender = this.user.gender;//tod;
		 this.form.contactPreference = this.user.contactPreference.length ? this.user.contactPreference.split(',') : []
		 this.form.website = this.user.website;
		 this.form.dob = this.user.dob;
		 this.form.categoryId = this.user.categoryId;
		 this.form.companyName = this.user.company.name;
		 this.form.activity = this.user.company.activity;
		 this.form.vat = this.user.company.vat?.toString();
		 this.form.doy = this.user.company.doy;
		 this.form.address = this.user.company.address;
		 this.form.city = this.user.company.city;
		 this.form.postcode = this.user.company.postcode?.toString();
		 this.form.state = this.user.company.state;
		 this.form.country = this.user.company.country;
		 this.form.notes = this.user.notes;


		 this.form.social = this.user.social.map(social => {
			return {
			   medium: social.medium,
			   url: social.url
			}
		 })

		 this.addSocialIfIsEmpty();


		 this.form.photoType = this.user.photo.split('.').pop();
		 // this.form.photo = await this.getBase64FromUrl(this.user.photo);
		 this.form.photo = this.user.photo;

	  }
   },


   async created() {
	  await this.$store.dispatch("fetchContact", this.$route.params.userId);
	  const response = await axiosIns.get(`/contact-category`, {
		 params: {
			rpp: 100
		 }
	  })
	  this.categories = response.data.data;
	  this.categoriesTitle = _.map(this.categories, 'title')


	  this.user = await this.$store.getters.getUserCompany;
	  await this.initCurrentUser();
	  // this.addSocialIfIsEmpty();

   }
}

</script>
<style lang="scss">
.ql-editor {
   min-height: 220px;
}
</style>
<style lang="scss"
	   scoped>
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';


.repeater-form {
   overflow: hidden;
   transition: .35s height;
}


.form-group {
   margin-bottom: 0;
}
</style>

