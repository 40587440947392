export default {
    methods: {
        onPreview(e, form) {
            const file = e.target.files[0];
            const reader = new FileReader()

            let rawImg;
            reader.onloadend = () => {
                rawImg = reader.result;
                const validate = ['png', 'jpg', 'jpeg', 'gif']

                if (validate.includes(this.base64Mime(rawImg))) {
                    form.photo = rawImg;
                    form.photoType = file.type.split('/').pop();
                    this.$refs.photoError?.classList.add('d-none')
                } else {
                    this.$refs.photoError.classList.remove('d-none')

                }
            }
            reader.readAsDataURL(file);
        },
        async removeImage(form) {
            form.photo = 'delete'
            form.photoType = 'jpg'
        },
        base64Mime(encoded) {
            var result = null;

            if (typeof encoded !== 'string') {
                return result;
            }

            var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);

            if (mime && mime.length) {
                result = mime[1];
            }

            return result?.split('/').pop();
        },

        async getBase64FromUrl(url) {
            const data = await fetch(url);
            const blob = await data.blob();
            return new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                    const base64data = reader.result;
                    resolve(base64data);
                }
            });
        },
        isBase64(str) {
            return str.length % 4 == 0 && /^[A-Za-z0-9+/]+[=]{0,2}$/.test(str);
        }

    }
};