import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
    methods: {
        notify(title, variant = 'success', text = '') {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: title,
                    icon: 'CheckIcon',
                    variant: variant,

                    text: text,
                },
            })
        },
        async swal(text = 'ΠΡΟΣΟΧΗ! Η διαγραφή είναι μόνιμη και δεν μπορεί να γίνει επαναφορά') {
            return await this.$swal({
                title: 'Είστε σίγουρος?',
                text: text,
                type: 'warning',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#7367f0',
                cancelButtonColor: '#82868b',
                confirmButtonText: 'Διαγραφή',
                cancelButtonText: 'Ακύρωση',
                reverseButtons:true,
                buttonsStyling: true,
                animation: true
            });
        }
    }
};